import { createCipheriv, createDecipheriv, randomBytes } from 'crypto'
import Cookies from 'js-cookie'

const secretKey = '72b66284a44a35cc0fcd1df2c1649a90'
const algorithm = 'aes-256-cbc'

const iv = randomBytes(16)

export const cookies = Cookies.withConverter({
  read: (value) => {
    const decipher = createDecipheriv(algorithm, Buffer.from(secretKey), iv)
    let decrypted = decipher.update(value, 'hex', 'utf8')
    decrypted += decipher.final('utf8')
    return decrypted
  },
  write: (value) => {
    const cipher = createCipheriv(algorithm, Buffer.from(secretKey), iv)
    let encrypted = cipher.update(String(value), 'utf8', 'hex')
    encrypted += cipher.final('hex')
    return encrypted
  },
})

export const getCookies = (value: string) => {
  try {
    const currentCookies = cookies.get(value)
    if (currentCookies !== undefined && currentCookies) {
      return JSON.parse(currentCookies)
    }
    return {}
  } catch (e) {
    return {}
  }
}

export default cookies
