import {
  HomeOutlined,
  AttachMoney,
  Cached,
  Receipt,
  RestorePageOutlined,
  SyncAlt,
  Gavel,
  Description,
  AccountBalance,
} from '@material-ui/icons'
import LazyLoading from 'components/LazyLoading'
import { routeCodes, subRouteCodes } from 'constants/routes'
import { hasPermission } from 'permissions'

const Default = LazyLoading(() => import('./pages/Default'))
const Dashboard = LazyLoading(() => import('./pages/Dashboard'))
const UserTerms = LazyLoading(() => import('./pages/UserTerms'))
const HireCredit = LazyLoading(() => import('./pages/HireCredit'))
const Simulate = LazyLoading(() => import('./pages/HireCredit/Simulate'))
const Location = LazyLoading(() => import('./pages/Location'))
const Resume = LazyLoading(() => import('./pages/HireCredit/Resume'))
const ProposalStatus = LazyLoading(() => import('./pages/HireCredit/ProposalStatus'))
const SignContract = LazyLoading(() => import('./pages/HireCredit/SignContract'))
const Renegotiate = LazyLoading(() => import('./pages/Renegotiate'))
const RenegotiateList = LazyLoading(() => import('./pages/Renegotiate/List'))
const RenegotiateSimulate = LazyLoading(() => import('./pages/Renegotiate/Simulate'))
const RenegotiateResume = LazyLoading(() => import('./pages/Renegotiate/Resume'))
const UpdateData = LazyLoading(() => import('./pages/UpdateData'))
const UpdateDataForm = LazyLoading(() => import('./pages/UpdateData/Form'))
const Contracts = LazyLoading(() => import('./pages/Contracts'))
const ContractsList = LazyLoading(() => import('./pages/Contracts/List'))
const ContractsView = LazyLoading(() => import('./pages/Contracts/View'))
const Proposals = LazyLoading(() => import('./pages/Proposals'))
const ProposalsList = LazyLoading(() => import('./pages/Proposals/List'))
const ProposalsView = LazyLoading(() => import('./pages/Proposals/View'))
const ContractSign = LazyLoading(() => import('./pages/Contracts/SignContract'))
const Portability = LazyLoading(() => import('./pages/Portability'))
const PortabilityList = LazyLoading(() => import('./pages/Portability/List'))
const PortabilitySimulate = LazyLoading(() => import('./pages/Portability/Simulate'))
const PortabilitySign = LazyLoading(() => import('./pages/Portability/Simulate/Forms/SignContract'))
const PortabilityResume = LazyLoading(() => import('./pages/Portability/Resume'))
const AutomaticRegulation = LazyLoading(() => import('./pages/AutomaticRegulation'))
const IncomeTax = LazyLoading(() => import('./pages/IncomeTax'))
const MyAccount = LazyLoading(() => import('app/Company/pages/MyAccount'))
const MyAccountList = LazyLoading(() => import('app/Company/pages/MyAccount/List'))
const MyAccountChangeMail = LazyLoading(() => import('app/Company/pages/MyAccount/ChangeMail'))
const MyAccountChangePassword = LazyLoading(() =>
  import('app/Company/pages/MyAccount/ChangePassword')
)
const MyAccountChangePhone = LazyLoading(() => import('app/Company/pages/MyAccount/ChangePhone'))
const MyAccountToken = LazyLoading(() => import('app/Company/pages/MyAccount/Token'))

export const EmployeeRoutes = {
  DEFAULT: {
    path: routeCodes.NULL_ROUTE,
    name: 'Página inicial',
    component: Default,
    hideInSidePanel: true,
  },
  DASHBOARD: {
    path: routeCodes.EMPLOYEE.DASHBOARD,
    name: 'Página Inicial',
    component: Dashboard,
    icon: HomeOutlined,
    defaultRoute: true,
  },
  USER_TERMS: {
    path: routeCodes.EMPLOYEE.USER_TERMS,
    name: 'Termos de uso',
    component: UserTerms,
    hideInSidePanel: true,
  },
  LOCATION: {
    path: routeCodes.EMPLOYEE.LOCATION,
    name: 'Habilitar localização',
    component: Location,
    hideInSidePanel: true,
  },
  HIRE_CREDIT: {
    path: routeCodes.EMPLOYEE.HIRE_CREDIT,
    name: 'Contratar Crédito',
    icon: AttachMoney,
    component: HireCredit,
    permissions: [hasPermission('CEP_FUNC_SIMULAR')],
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Simulação de crédito',
        component: Simulate,
      },
      CONFIRM_DATA: {
        path: subRouteCodes.EMPLOYEE.HIRE_CREDIT.CONFIRM_DATA,
        name: 'Atualizar dados',
        component: UpdateDataForm,
      },
      RESUME: {
        path: subRouteCodes.EMPLOYEE.HIRE_CREDIT.RESUME,
        name: 'Resumo',
        component: Resume,
      },
      PROPOSAL_STATUS: {
        path: subRouteCodes.EMPLOYEE.HIRE_CREDIT.STATUS,
        name: 'Andamento da Proposta',
        component: ProposalStatus,
      },
      SIGN: {
        path: subRouteCodes.EMPLOYEE.HIRE_CREDIT.SIGN,
        name: 'Assinar contrato',
        component: SignContract,
      },
    },
  },
  RENEGOTIATE: {
    path: routeCodes.EMPLOYEE.RENEGOTIATE,
    name: 'Renegociar Crédito',
    icon: RestorePageOutlined,
    permissions: [hasPermission('CEP_FUNC_RENEGOCIACOES_GET')],
    component: Renegotiate,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Renegociar Crédito',
        component: RenegotiateList,
      },
      SIMULATE: {
        path: subRouteCodes.EMPLOYEE.RENEGOTIATE.SIMULATE,
        name: 'Renegociar Crédito',
        component: RenegotiateSimulate,
      },
      CONFIRM_DATA: {
        path: subRouteCodes.EMPLOYEE.RENEGOTIATE.CONFIRM_DATA,
        name: 'Atualizar dados',
        component: UpdateDataForm,
      },
      RESUME: {
        path: subRouteCodes.EMPLOYEE.RENEGOTIATE.RESUME,
        name: 'Resumo',
        component: RenegotiateResume,
      },
    },
  },
  PROPOSALS: {
    path: routeCodes.EMPLOYEE.PROPOSALS,
    name: 'Propostas',
    icon: Description,
    permissions: [hasPermission('CEP_FUNC_CONTRATOS_GET')],
    component: Proposals,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Consultar dados',
        component: ProposalsList,
      },
      VIEW: {
        path: '/:id',
        name: 'Visualizar Contrato',
        component: ProposalsView,
      },
      ANALYSIS: {
        path: '/analysis',
        name: 'Consultar dados',
        component: ProposalsList,
      },
      DENIED: {
        path: '/denied',
        name: 'Consultar dados',
        component: ProposalsList,
      },
    },
  },
  CONTRACTS: {
    path: routeCodes.EMPLOYEE.CONTRACTS,
    name: 'Contratos',
    icon: Receipt,
    permissions: [hasPermission('CEP_FUNC_CONTRATOS_GET')],
    component: Contracts,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Consultar dados',
        component: ContractsList,
      },
      VIEW: {
        path: '/:id',
        name: 'Visualizar Contrato',
        component: ContractsView,
      },
      SIGN: {
        path: '/sign',
        name: 'Assinar contrato',
        component: ContractSign,
      },
      ANALYSIS: {
        path: '/analysis',
        name: 'Consultar dados',
        component: ContractsList,
      },
      DENIED: {
        path: '/denied',
        name: 'Consultar dados',
        component: ContractsList,
      },
      REVERSED: {
        path: '/reversed',
        name: 'Consultar dados',
        component: ContractsList,
      },
      FINISHED: {
        path: '/finished',
        name: 'Consultar dados',
        component: ContractsList,
      },
      PENDIDNG: {
        path: '/pending',
        name: 'Consultar dados',
        component: ContractsList,
      },
    },
  },
  UPDATE_DATA: {
    path: routeCodes.EMPLOYEE.UPDATE_DATA,
    name: 'Atualizar dados',
    icon: Cached,
    component: UpdateData,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Atualizar dados',
        component: UpdateDataForm,
      },
    },
  },
  PORTABILITY: {
    path: routeCodes.EMPLOYEE.PORTABILITY,
    name: 'Portabilidade',
    icon: SyncAlt,
    component: Portability,
    permissions: [hasPermission('CEP_FUNC_PORTABILIDADES_GET')],
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Portabilidade',
        component: PortabilityList,
      },
      SIMULATE: {
        path: subRouteCodes.EMPLOYEE.PORTABILITY.SIMULATE,
        name: 'Simular',
        component: PortabilitySimulate,
      },
      RESUME: {
        path: subRouteCodes.EMPLOYEE.PORTABILITY.RESUME,
        name: 'Andamento da portabilidade',
        component: PortabilityResume,
      },
      SIGN: {
        path: subRouteCodes.EMPLOYEE.PORTABILITY.SIGN,
        name: 'Assinar portabilidade',
        component: PortabilitySign,
      },
    },
  },
  MY_ACCOUNT: {
    path: routeCodes.EMPLOYEE.MY_ACCOUNT,
    name: 'Minha Conta',
    component: MyAccount,
    hideInSidePanel: true,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Minha Conta',
        component: MyAccountList,
      },
      CHANGE_MAIL: {
        path: subRouteCodes.EMPLOYEE.MY_ACCOUNT.CHANGE_MAIL,
        name: 'Alterar Email',
        component: MyAccountChangeMail,
      },
      CHANGE_PASSWORD: {
        path: subRouteCodes.EMPLOYEE.MY_ACCOUNT.CHANGE_PASSWORD,
        name: 'Alterar Senha',
        component: MyAccountChangePassword,
      },
      CHANGE_TELEPHONE: {
        path: subRouteCodes.EMPLOYEE.MY_ACCOUNT.CHANGE_PHONE,
        name: 'Alterar Telefone',
        component: MyAccountChangePhone,
      },
      TOKEN: {
        path: subRouteCodes.EMPLOYEE.MY_ACCOUNT.TOKEN,
        name: 'Confirmar código',
        component: MyAccountToken,
      },
    },
  },
  INCOME_TAX: {
    path: routeCodes.EMPLOYEE.INCOME_TAX,
    name: 'Informe do IR',
    component: IncomeTax,
    icon: Gavel,
  },
  AUTOMATIC_REGULATION: {
    path: routeCodes.EMPLOYEE.AUTOMATIC_REGULATION,
    name: 'Autorregulação',
    component: AutomaticRegulation,
    icon: AccountBalance,
  },
}

export default EmployeeRoutes
