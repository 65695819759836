import React from 'react'
import { range } from 'lodash'
import { useHistory, Link } from 'react-router-dom'
import { Typography, Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Apps } from '@material-ui/icons'
import { TLayout } from 'types'
import { useCore } from 'hooks'
import Permissions from 'components/Permissions'
import DefaultEntity from 'app/Default'

import {
  SideBar as SideBarComponent,
  Logo,
  SidebarItem,
  SidebarOverlay,
  Dehaze,
  SidebarCompanies,
  CompanyInfos,
} from './styles'

const SideBar: React.FC<TLayout> = ({
  setIsCollapsed,
  isCollapsed,
  setIsOpened,
  isSidePanelVisible,
}) => {
  const { entity, resetEntity } = useCore()
  const history = useHistory()
  const LogoSvg = entity.logo.default
  const isLoading = !(entity.route && entity.funcao)

  return (
    <>
      <SideBarComponent>
        <Logo>
          {!isCollapsed && LogoSvg && <LogoSvg />}
          {isSidePanelVisible && <Dehaze onClick={setIsCollapsed} />}
        </Logo>
        {(entity?.funcoes?.length || 0) > 0 && (
          <SidebarCompanies>
            <CompanyInfos isCollapsed={isCollapsed}>
              <Typography style={{ color: 'white' }}>
                {entity?.funcoes?.length} empresas selecionadas
              </Typography>
            </CompanyInfos>
            <Apps
              fontSize={isCollapsed ? 'default' : 'large'}
              className='cursor-pointer'
              onClick={() => {
                resetEntity()
                history.push(DefaultEntity.route.PROFILES.path)
              }}
            />
          </SidebarCompanies>
        )}
        {!isLoading &&
          Object.keys(entity.route).map((key) => {
            const currentRoute = entity.route[key]
            const Icon = currentRoute.icon
            const isActive = history.location.pathname.includes(currentRoute.path) ? 1 : 0
            if (!currentRoute.icon) {
              return null
            }
            return (
              <Permissions
                permissions={currentRoute.permissions}
                disableAction
                key={currentRoute.path}
              >
                <Link
                  to={`${entity.path}${currentRoute.sideBarPath || currentRoute.path}`}
                  onClick={setIsOpened}
                >
                  <SidebarItem active={isActive} key={currentRoute.name} isCollapsed={isCollapsed}>
                    {Icon && <Icon />}
                    <Box ml={1} />
                    <Typography>{currentRoute.name}</Typography>
                  </SidebarItem>
                </Link>
              </Permissions>
            )
          })}
        {isLoading &&
          range(0, 5).map((i) => (
            <Box mb={1} px={1} mt={i === 0 ? 2 : 0} key={i}>
              <Skeleton variant='rect' width='100%' height={50} />
            </Box>
          ))}
      </SideBarComponent>
      <SidebarOverlay onClick={() => setIsOpened && setIsOpened()} />
    </>
  )
}

export default React.memo(SideBar)
